import React from "react";

import "./Furniture.css";

import Architecture_dt from "../images/architecture.png";
import Architecture_mb from "../images/architecture_mobile.png";



export default function Architecture() {
  return (
    <div className="furniture-container">
      <div className="furniture-dt-view">
        <img src={Architecture_dt} className="furniture-dt-bg" alt="Display of Wyatt Studio's Architectural Projects: stair cases, hand rails, planter boxes, gates."/>
      </div>
      <div className="furniture-mb-view">
        <img src={Architecture_mb} className="furniture-mb-bg" alt="Display of Wyatt Studio's Architectural Projects: stair cases, hand rails, planter boxes, gates."/>
      </div>
    </div>

  );
}
