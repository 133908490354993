import React from "react";

import "./Furniture.css";


import Furniture_dt from "../images/furniture.png";
import Furniture_mb from "../images/furniture_mobile.png";


export default function Furniture() {

  return (
    <div className="furniture-container">
      <div className="furniture-dt-view">
        <img src={Furniture_dt} className="furniture-dt-bg" alt="Show case of Wyatt Studio's Custome Furniture: Light fixtures, chairs, table stands."/>
      </div>
      <div className="furniture-mb-view">
        <img src={Furniture_mb} className="furniture-mb-bg" alt="Show case of Wyatt Studio's Custome Furniture: Light fixtures, chairs, table stands."/>
      </div>
    </div>

  );
}
