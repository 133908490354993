import React from "react";
import { Route, Switch } from "react-router-dom";

import Furniture from "./containers/Furniture";
import Architecture from "./containers/Architecture";
import Structural from "./containers/Structural";
import Contact from "./containers/Contact";
import About from "./containers/About";
import NotFound from "./containers/NotFound";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Furniture />
      </Route>

      <Route exact path="/architecture">
        <Architecture />
      </Route>

      <Route exact path="/structural">
        <Structural />
      </Route>

      <Route exact path="/about">
        <About />
      </Route>

      <Route exact path="/contact">
        <Contact />
      </Route>

      <Route>
        <NotFound />
      </Route>

    </Switch>
  );
}
