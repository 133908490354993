import React from "react";

import "./Furniture.css";


import Structural_dt from "../images/structural.png";
import Structural_mb from "../images/structural_mobile.png";


export default function Structural() {

  return (
    <div className="furniture-container">
      <div className="furniture-dt-view">
        <img src={Structural_dt} className="furniture-dt-bg" alt="showcase of custom welded structural beams"/>
      </div>
      <div className="furniture-mb-view">
        <img src={Structural_mb} className="furniture-mb-bg" alt="showcase of custom welded structural beams"/>
      </div>
    </div>
  );
}
