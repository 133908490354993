import React from "react";

import  About_dt from "../images/about_desktop.png";
import  About_mb from "../images/about_mobile.png";
import "./Furniture.css";


export default function About() {


  return (
    <div className="furniture-container">
      <div className="furniture-dt-view">
        <img src={About_dt} className="furniture-dt-bg" alt="IN 2006, Wyatt Ellison started Wyatt Studio which specializes in high-end
         welding services for theme oriented,artistic type applications, primarily targeted for restaurants, wineries, and commercial developments,
         as well as private residences.  Please inquire via our contact page with your project needs."/>
      </div>
      <div className="furniture-mb-view">
        <img src={About_mb} className="furniture-mb-bg" alt="IN 2006, Wyatt Ellison started Wyatt Studio which specializes in high-end
         welding services for theme oriented,artistic type applications, primarily targeted for restaurants, wineries, and commercial developments,
         as well as private residences.  Please inquire via our contact page with your project needs."/>
      </div>
    </div>

  );
}




// <div className="about-text">
//   <p>IN 2006, Wyatt Ellison started <b>Wyatt Studio</b> which specializes in high-end welding services for theme oriented,
//   artistic type applications, primarily targeted for restaurants, wineries, and commercial developments, as well
//    as private residences.  Please inquire via our contact page with your project needs.</p>
// </div>
