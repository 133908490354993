import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";


import "./App.css";




function App() {

  return (
    <div className="app-container">
      <div className="nav">
      <LinkContainer to="/" className="link-container text-link furniture-link">
           <div className="top-nav"><div className="link-word">Furniture</div></div>
     </LinkContainer>
     <LinkContainer to="/architecture" className="link-container text-link architecture-link">
          <div className="top-nav"><div className="link-word">Architecture</div></div>
      </LinkContainer>
     <LinkContainer to="/structural" className="link-container text-link structural-link">
         <div className="top-nav"><div className="link-word">Structural</div></div>
     </LinkContainer>
     <LinkContainer to="/about" className="link-container text-link about-link">
        <div className="top-nav"><div className="link-word">About</div></div>
     </LinkContainer>
     <LinkContainer to="/contact" className="link-container text-link contact-link">
        <div className="top-nav"><div className="link-word">Contact</div></div>
     </LinkContainer>
     </div>

      <Routes  />

      <div className="bottom-nav">
      <LinkContainer to="/" className="link-container text-link furniture-link">
           <div className="top-nav"><div className="link-word">Furniture</div></div>
     </LinkContainer>
     <LinkContainer to="/architecture" className="link-container text-link architecture-link">
          <div className="top-nav"><div className="link-word">Architecture</div></div>
      </LinkContainer>
     <LinkContainer to="/structural" className="link-container text-link structural-link">
         <div className="top-nav"><div className="link-word">Structural</div></div>
     </LinkContainer>
     <LinkContainer to="/about" className="link-container text-link about-link">
        <div className="top-nav"><div className="link-word">About</div></div>
     </LinkContainer>
     <LinkContainer to="/contact" className="link-container text-link contact-link">
        <div className="top-nav"><div className="link-word">Contact</div></div>
     </LinkContainer>
     </div>
    </div>
  );

}
export default App;

//
//alt nav-bar for transparent bg
//   <div className="navbar transparent navbar-inverse top-level">

// return (
//   <div className="App container py-3">
//     <Navbar variant="light" collapseOnSelect  expand="md" className="wyatt-nav-bar mb-3 navbar-dark">
//       <LinkContainer to="/">
//         <Navbar.Brand className="nav-logo-text">
//           <img src={header_logo} className="nav-text-logo" alt="Wyatt Studio"/>
//         </Navbar.Brand>
//       </LinkContainer>
//       <Navbar.Toggle />
//       <Navbar.Collapse className="justify-content-end">
//         <Nav activeKey={window.location.pathname}>
//         <LinkContainer to="/ornamental">
//           <Nav.Link className="link-style">Ornamental</Nav.Link>
//         </LinkContainer>
//         <LinkContainer to="/architecture">
//           <Nav.Link disabled className="link-style link-style2">Architectural</Nav.Link>
//         </LinkContainer>
//         <LinkContainer to="/structural">
//           <Nav.Link disabled className="link-style">Structural</Nav.Link>
//         </LinkContainer>
//           <LinkContainer to="/about" className="desktop-only">
//             <Nav.Link className="link-style">About</Nav.Link>
//           </LinkContainer>
//           <LinkContainer to="/contact" className="desktop-only">
//             <Nav.Link className="link-style">Contact</Nav.Link>
//           </LinkContainer>
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//     <Routes />
//   </div>
// );
