import React from "react";

import  Contact_dt from "../images/contact_desktop.png";
import  Contact_mb from "../images/contact_mobile.png";
import "./Furniture.css";


export default function Contact() {


  return (
    <div className="furniture-container">
      <div className="furniture-dt-view">
        <img src={Contact_dt} className="furniture-dt-bg" alt="Contact Wyatt Studio. Email: wyatt@wyattstudio.com |  Phone: 415-559-9131   | Address: 166 Hamilton Dr suite B Novato, CA 94949 "/>
        <a href="mailto:wyatt@wyattstudio.com"><div className="email-link"></div></a>
      </div>
      <div className="furniture-mb-view">
        <img src={Contact_mb} className="furniture-mb-bg" alt="Contact Wyatt Studio. Email: wyatt@wyattstudio.com  |  Phone: 415-559-9131  | Address: 166 Hamilton Dr suite B Novato, CA 94949 "/>
      </div>
    </div>

  );
}
